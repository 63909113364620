import React from 'react';
import styled from 'styled-components';
import FacebookButton from './FacebookButton';
import HatenaButton from './HatenaButton';
import LineButton from './LineButton';
import TwitterButton from './TwitterButton';

type Props = {
  url: string;
  text: string;
  hashtags: readonly string[];
};

const ShareLinks = (props: Props) => {
  const { url, text, hashtags } = props;
  return (
    <Wrapper>
      <TwitterButton text={text} url={url} hashtags={hashtags} />
      <HatenaButton url={url} />
      <FacebookButton url={url} hashtag={hashtags[0]} />
      <LineButton url={url} />
    </Wrapper>
  );
};

ShareLinks.defaultProps = {
  text: '',
  hashtags: []
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ShareLinks;
