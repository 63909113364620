import { stringify } from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import FacebookIcon from '../../icons/Facebook/Facebook';
import DocumentHelper from '../../utils/DocumentHelper';

type Props = {
  url: string;
  hashtag?: string;
};

interface FBWindow extends Window {
  FB?: {
    init: (options: { [s: string]: unknown }) => void;
    ui: (options: { [s: string]: unknown }) => void;
  };
}
declare const window: FBWindow;

const ICON_SIZE = 40;

const styles = {
  width: `${ICON_SIZE}px`,
  height: `${ICON_SIZE}px`,
  cursor: 'pointer',
  outline: 'none',
  overflow: 'hidden'
};

const fetchAppId = () => {
  const metaTags = document.getElementsByTagName('meta');
  for (let i = 0; i < metaTags.length; i += 1) {
    const meta = metaTags[i];
    if (meta.getAttribute('property') === 'fb:app_id') {
      return meta.getAttribute('content');
    }
  }
  return null;
};

const FacebookButton = (props: Props) => {
  const { url, hashtag } = props;
  const isInitialized = useRef(false);
  const appId = useRef<string | null>(null);
  const href = useMemo(() => {
    const query = {
      u: url
    };
    return `https://www.facebook.com/sharer/sharer.php?${stringify(query)}`;
  }, [url]);
  const share = useCallback(() => {
    if (window.FB != null && isInitialized.current) {
      window.FB.ui({
        hashtag,
        method: 'share',
        href: url,
        mobile_iframe: true
      });
    } else {
      window.open(
        href,
        'FBwindow',
        'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes'
      );
    }
  }, []);
  const initialize = useCallback(() => {
    if (window.FB != null && appId.current != null) {
      window.FB.init({ appId: appId.current, version: 'v2.12' });
      isInitialized.current = true;
    }
  }, []);
  useEffect(() => {
    appId.current = fetchAppId();
    if (appId.current != null) {
      DocumentHelper.importScript(
        'facebook-jssdk',
        '//connect.facebook.net/ja_JP/sdk.js',
        true,
        initialize
      );
    }
  }, []);
  return (
    <div role="button" tabIndex={0} onClick={share} style={styles} title="Facebookでシェア">
      <FacebookIcon size={ICON_SIZE} />
    </div>
  );
};

export default FacebookButton;
