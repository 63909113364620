import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Avatar, { AvatarProps } from './Avatar';
import { rhythm } from '../utils/typography';

export type DetailsProps = {
  displayName: string;
  description: string;
  ruby: string;
};

type Props = {
  avatar: AvatarProps;
  details: DetailsProps;
};

const Bio = (props: Props) => {
  const { avatar, details } = props;
  return (
    <Wrapper>
      <Profile>
        <Avatar avatar={avatar} alt={details.displayName} />
        <Author>
          <Name>{details.displayName}</Name>
          {details.ruby !== '' && <Ruby>{details.ruby}</Ruby>}
        </Author>
      </Profile>
      <Description>{details.description}</Description>
    </Wrapper>
  );
};

export const query = graphql`
  fragment BioDetailFragment on AuthorsJson {
    displayName
    description
    ruby
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: ${rhythm(0.75)};
  border-radius: 2px;
  background-color: #eee;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`;

const Author = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: ${rhythm(0.5)};
`;

const Name = styled.h4`
  margin: 0;
`;

const Ruby = styled.h5`
  margin: 0 0 0 ${rhythm(0.25)};
`;

const Description = styled.p`
  margin: ${rhythm(0.75)} 0 0;
  font-size: ${rhythm(0.5)};
  color: rgba(0, 0, 0, 0.8);
`;

export default Bio;
