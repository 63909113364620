import React from 'react';
import styled from 'styled-components';
import twitter from './twitter.png';

type Props = {
  size: number;
};

const Twitter = (props: Props) => {
  const { size } = props;
  return <StyledImg src={twitter} alt="twitter" width={size} height={size} />;
};

Twitter.defaultProps = {
  size: 40
};

const StyledImg = styled.img`
  margin-bottom: 0;
`;

export default Twitter;
