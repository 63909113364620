import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Avatar, { AvatarProps } from '../components/Avatar';
import Bio, { DetailsProps } from '../components/Bio';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import Ogp, { SiteProps } from '../components/Ogp';
import { PostProps } from '../components/Post';
import PostList, { PostListProps } from '../components/PostList';
import ShareLinks from '../components/ShareLinks/ShareLinks';
import TagLink from '../components/TagLink';
import { DEFAULT_OGP_IMAGE } from '../constants/src';
import { TEXT_COLOR } from '../constants/styles';
import ArticleIcon from '../icons/Article/Article';
import { rhythm } from '../utils/typography';

type Props = {
  data: {
    site: SiteProps;
    avatar: AvatarProps;
    details: DetailsProps;
    post: {
      html: string;
      excerpt: string;
      fields: PostProps['fields'];
      frontmatter: Pick<PostProps['frontmatter'], 'date' | 'tags' | 'title'> & {
        ogp: {
          publicURL: string;
          childImageSharp: {
            fluid: FluidObject;
          };
        };
      };
    };
    allMarkdownRemark: {
      relativePosts: PostListProps;
    };
  };
};

const BlogPost = (props: Props) => {
  const { data } = props;
  const { site, post } = data;
  const url = `${site.siteMetadata.siteUrl}${post.fields.slug}`;
  const image = useMemo(() => {
    const { ogp } = post.frontmatter;
    if (ogp != null) {
      return <OgpImg fluid={ogp.childImageSharp.fluid} alt="" />;
    }
    return <img src={DEFAULT_OGP_IMAGE} alt="" />;
  }, []);
  return (
    <Layout>
      <Wrapper>
        <Ogp
          metadata={data.site}
          description={post.excerpt}
          image={post.frontmatter.ogp != null ? post.frontmatter.ogp.publicURL : null}
          title={post.frontmatter.title}
          url={url}
        />
        <Article>
          <Detail>
            <Author>
              <Avatar avatar={data.avatar} alt={data.details.displayName} size={32} />
              <Name>{data.details.displayName}</Name>
            </Author>
            <Date>{post.frontmatter.date}</Date>
          </Detail>
          <PostTitle>{post.frontmatter.title}</PostTitle>
          <Tags>
            {post.frontmatter.tags.map(tag => (
              <TagLink key={tag} name={tag} />
            ))}
          </Tags>
          <Shares>
            <ShareLinks text={post.frontmatter.title} url={url} />
          </Shares>
          {image}
          <Body dangerouslySetInnerHTML={{ __html: post.html }} />
          <Bio avatar={data.avatar} details={data.details} />
        </Article>
        {data.allMarkdownRemark != null && (
          <RelativePost>
            <Heading icon={<ArticleIcon alt="関連記事" />}>関連記事</Heading>
            <PostList posts={data.allMarkdownRemark.relativePosts} />
          </RelativePost>
        )}
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery($author: String!, $category: String!, $slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
      ...OgpFragment
    }
    avatar: file(name: { eq: $author }, extension: { regex: "/(jpg|jpeg|png)$/" }) {
      ...AvatarFragment
    }
    details: authorsJson(name: { eq: $author }) {
      displayName
      ...BioDetailFragment
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        ogp {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        tags
        title
      }
    }
    allMarkdownRemark(
      limit: 3
      filter: { frontmatter: { category: { eq: $category } }, fields: { slug: { ne: $slug } } }
    ) {
      relativePosts: edges {
        ...PostListFragment
      }
    }
  }
`;

const Wrapper = styled.section`
  position: relative;
`;

const Article = styled.article`
  max-width: 800px;
  margin: 0 auto ${rhythm(1.5)};
`;

const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${rhythm(0.5)};
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`;

const Name = styled.h5`
  margin: 0 0 0 ${rhythm(0.25)};
  color: ${TEXT_COLOR};
`;

const Date = styled.div`
  margin-left: ${rhythm(0.5)};
  text-align: right;
  font-size: ${rhythm(0.6)};
  color: rgba(0, 0, 0, 0.5);
`;

const PostTitle = styled.h1`
  margin: 0 0 ${rhythm(0.5)};
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${rhythm(0.25)};
`;

const Shares = styled.div`
  max-width: 200px;
  margin-bottom: ${rhythm(0.5)};
`;

const OgpImg = styled(Img)`
  margin-bottom: ${rhythm(1)};
`;

const Body = styled.article`
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  line-height: ${rhythm(1.25)};

  a:hover {
    opacity: 0.8;
  }
`;

const RelativePost = styled.div`
  position: relative;
`;

export default BlogPost;
