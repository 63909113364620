import React from 'react';
import facebook from './facebook.png';

type Props = {
  size: number;
};

const Facebook = (props: Props) => {
  const { size } = props;
  return <img src={facebook} alt="facebook" width={size} height={size} />;
};

Facebook.defaultProps = {
  size: 40
};

export default Facebook;
