import React from 'react';
import { graphql } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';
import styled from 'styled-components';

export type AvatarProps = {
  childImageSharp: {
    fixed: FixedObject;
  };
};

type Props = {
  avatar: AvatarProps;
  alt: string;
  size: number;
};

type StyledProps = Pick<Props, 'size'>;

const Avatar = (props: Props) => {
  const { avatar, alt, size } = props;
  return <StyledAvatar fixed={avatar.childImageSharp.fixed} alt={alt} size={size} />;
};

Avatar.defaultProps = {
  alt: '',
  size: 50
};

export const query = graphql`
  fragment AvatarFragment on File {
    childImageSharp {
      fixed(width: 128) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`;

const StyledAvatar = styled(Img)<StyledProps>`
  margin: 0;
  max-width: ${props => `${props.size}px`};
  max-height: ${props => `${props.size}px`};
  border-radius: 50%;
`;

export default Avatar;
