import React, { useEffect } from 'react';
import DocumentHelper from '../../utils/DocumentHelper';

type Props = {
  url: string;
};

const HatenaButton = (props: Props) => {
  const { url } = props;
  const entryUrl = url.split('https://');
  useEffect(() => {
    DocumentHelper.importScript('hatena-bk', 'https://b.st-hatena.com/js/bookmark_button.js', true);
  }, []);
  return (
    <a
      href={`http://b.hatena.ne.jp/entry/s/${entryUrl[1]}`}
      className="hatena-bookmark-button"
      data-hatena-bookmark-layout="touch"
      data-hatena-bookmark-width="40"
      data-hatena-bookmark-height="40"
      title="このエントリーをはてなブックマークに追加"
    >
      <img
        src="https://b.st-hatena.com/images/entry-button/button-only@2x.png"
        alt="このエントリーをはてなブックマークに追加"
        width="40"
        height="40"
      />
    </a>
  );
};

export default HatenaButton;
