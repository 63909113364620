import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { stringify } from 'query-string';
import TwitterIcon from '../../icons/Twitter/Twitter';
import DocumentHelper from '../../utils/DocumentHelper';

type Props = {
  url: string;
  text: string;
  hashtags: readonly string[];
};

const TwitterButton = (props: Props) => {
  const { url, text, hashtags } = props;
  const href = useMemo(() => {
    let hashtagsQuery;
    if (Array.isArray(hashtags) && hashtags.length > 0) {
      hashtagsQuery = hashtags.join(',');
    }
    const query = {
      text,
      url,
      hashtags: hashtagsQuery
    };
    return `https://twitter.com/intent/tweet?${stringify(query)}`;
  }, [url]);
  useEffect(() => {
    DocumentHelper.importScript('twitter-wjs', 'https://platform.twitter.com/widgets.js');
  }, []);
  return (
    <Link href={href} title="Twitterでシェア">
      <TwitterIcon />
    </Link>
  );
};

TwitterButton.defaultProps = {
  text: '',
  hashtags: []
};

const Link = styled.a`
  font-size: 0;
  line-height: 0;
`;

export default TwitterButton;
