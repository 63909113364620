import React, { useEffect, useCallback } from 'react';
import DocumentHelper from '../../utils/DocumentHelper';

type Props = {
  url: string;
  type: string;
};

interface LineItWindow extends Window {
  LineIt?: {
    loadButton: () => void;
  };
}
declare const window: LineItWindow;

const LineButton = (props: Props) => {
  const { url, type } = props;
  const loadButton = useCallback(() => {
    if (window.LineIt != null) {
      window.LineIt.loadButton();
    }
  }, []);
  useEffect(() => {
    DocumentHelper.importScript(
      'line-it',
      'https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js',
      true,
      loadButton
    );
  }, []);
  return (
    <div
      className="line-it-button"
      data-lang="ja"
      data-type={type}
      data-url={url}
      style={{ display: 'none' }}
    />
  );
};

LineButton.defaultProps = {
  type: 'share-d'
};

export default LineButton;
